<template>
  <div class="row">
    <div class="col-12">
      <!-- 평가대상 목록 -->
      <c-table
        ref="table"
        title="LBL0001091"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="process.targets"
        :merge="grid.merge"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
        :editable="editable&&!disabled"
        selection="multiple"
        rowKey="ramChecklistAssessScenarioId"
        @innerBtnClicked="innerBtnClicked"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-show="editable&&!disabled&&isSelect" label="LBLADDDIRECTLY" icon="add" @btnClicked="addKeyinTarget" />
            <c-btn v-show="editable&&!disabled&&isSelect" label="LBLADD" icon="add" @btnClicked="addTarget" />
            <c-btn v-show="editable&&!disabled&&isSelect" label="LBLREMOVE" icon="remove" @btnClicked="removeScenario" />
            <c-btn 
              v-show="editable&&!disabled&&isSelect" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="targetScenarioSave"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveTarget"
              @btnCallback="saveTargetCallback" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='ramCheckItemClassName'">
            {{props.row[col.name]}}
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                v-if="editable&&!disabled&&isSelect"
                icon="add"
                color="green"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.stop="innerBtnClicked(col, props, '1')">
                <q-tooltip>
                  <!-- 직접추가 -->
                  {{$label('LBLADDDIRECTLY')}}
                </q-tooltip>
              </q-btn>
              <q-btn
                v-if="editable&&!disabled&&isSelect&&props.row.ramCheckItemClassCd !== 'RCIC000015'"
                icon="add"
                color="amber"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.stop="innerBtnClicked(col, props, '2')">
                <q-tooltip>
                  <!-- 추가 -->
                  {{$label('LBLADD')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-else-if="col.name==='checkItemName'">
            <template v-if="props.row.checkItemId && props.row.ramCheckItemClassCd !== 'RCIC000015'">
              <!-- 작업, 기계·기구, 유해인자 & link 걸린 대상 -->
              <q-btn
                flat
                class="tableinnerBtn"
                align="left"
                color="blue-6"
                :label="props.row[col.name]"
                @click.stop="linkClick(props.row, col)" />
            </template>
            <template v-else>
              <!-- 기타 & link 걸리지 않은 대상 -->
              <c-text-column
                :editable="editable&&!disabled&&isSelect"
                :col="col"
                :props="props"
                @datachange="tableDataChange(props, col)"
              />
            </template>
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                v-if="editable&&!disabled&&isSelect"
                icon="add"
                color="blue"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.stop="innerBtnClicked(col, props)">
                <q-tooltip>
                  <!-- 추가 -->
                  {{$label('LBLADD')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
        </template>
      </c-table>
    </div>
    <q-dialog v-model="dialog" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <!-- 평가대상 분류 -->
          <c-card title="LBL0001096" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable&&!disabled&&isSelect" 
                  label="LBLCONFIRM" 
                  icon="save" 
                  @btnClicked="accepts"/>
                <c-btn 
                  label='LBLCLOSE' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-select
                  ref="ramCheckItemClassSelect"
                  :required="true"
                  :editable="editable&&!disabled"
                  codeGroupCd="RAM_CHECK_ITEM_CLASS_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="ramCheckItemClassCd"
                  label="LBLCLASSIFICATION"
                  v-model="target.ramCheckItemClassCd"
                ></c-select>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'checklistTargetItem',

  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workingTime: 0,  // 작업시간(1회)
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        targets: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'ramCheckItemClassCd' },
          { index: 1, colName: 'checkItemName' },
        ],
        columns: [
          {
            name: 'ramCheckItemClassName',
            field: 'ramCheckItemClassName',
            label: 'LBLCLASSIFICATION',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'custom',
          },
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            // 평가대상
            label: 'LBL0001028',
            align: 'left',
            type: 'custom',
            sortable: true,
            style: 'width:200px',
          },
          {
            required: true,
            name: 'checklistQuestion',
            field: 'checklistQuestion',
            // 체크리스트 문항
            label: 'LBL0001092',
            align: 'left',
            type: 'text',
            sortable: true,
            style: 'width:350px',
          },
        ],
        data: [],
        height: '550px'
      },
      target: {
        ramCheckItemClassCd: null,  // 분류코드
      },
      targetScenarioSave: {
        ramRiskAssessmentPlanId: '',
        processCd: '',
        targets: [],
        scenarios: [],
      },
      dialog: false,
      editable: true,
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    isSelect() {
      return this.process && this.process.processCd && !this.disabled ? true : false
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.checklist.target.list.url
      this.saveUrl = transactionConfig.ram.checklist.target.save.url
      this.deleteUrl = transactionConfig.ram.checklist.target.delete.url
      // list setting
    },
    getTargets() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd
      }
      this.$http.request((_result) => {
        this.process.targets = _result.data
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'checkItemName') {
        if (props.row.editFlag !== 'C' && props.row.targetEditFlag !== 'C') {
          props.row.targetEditFlag = 'U'
          props.row.chgUserId = this.$store.getters.user.userId
        }
      } else if (col.name === 'checklistQuestion') {
        if (props.row.editFlag === 'U' && props.row.scenarioEditFlag !== 'C') {
          props.row.scenarioEditFlag = 'U'
        }
      }
    },
    linkClick(row) {
      if (row.ramCheckItemClassCd === 'RCIC000001') {
        this.popupOptions.title = 'LBL0001093'; // 안전작업표준 상세
        this.popupOptions.param = {
          mdmSopId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${'@/pages/mdm/pim/sopDetail.vue'}`);
      } else if (row.ramCheckItemClassCd === 'RCIC000005') {
        this.popupOptions.title = "LBL0000956"; // 유해위험기계기구 상세
        this.popupOptions.param = {
          hhmHazardousMachineryId: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      } else {
        this.popupOptions.title = 'LBL0001094'; // 유해인자 상세
        this.popupOptions.param = {
          hazardCd: row.checkItemId,
        };
        this.popupOptions.target = () => import(`${"@/pages/sai/wmr/harmfulFactorsDetail.vue"}`);
      }
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    closeDialog() {
      this.dialog = false;
    },
    addKeyinTarget() {
      this.dialog = true;
    },
    addTarget() {
      this.popupOptions.title = 'LBL0001095'; // 평가대상 검색
      this.popupOptions.param = {
        processCd: this.process.processCd
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/checklist/checklistTargetPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTargetPopup;
    },
    closeTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.targets, { checkItemId: item.checkItemId }) === -1) {
            let idx = this.$_.findIndex(this.process.targets, { ramCheckItemClassCd: item.ramCheckItemClassCd });
            this.process.targets.splice((idx > -1 ? idx : 0), 0, {
              ramChecklistAssessScenarioId: uid(),  // 시나리오 일련번호
              ramAssessChecklistTargetId: (idx > -1 ? this.process.targets[idx].ramAssessChecklistTargetId : uid()),  // 공종/유해인자/설비 일련번호
              ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
              processCd: this.process.processCd,  // 단위공정 코드
              processName: this.process.processName,  // 단위공정명
              ramCheckItemClassCd: item.ramCheckItemClassCd,  // 분류코드
              ramCheckItemClassName: item.ramCheckItemClassName,  // 분류코드
              checkItemId: item.checkItemId,  // 분류id
              checkItemName: item.checkItemName,  // 분류명
              checklistQuestion: '',  // 체크리스트 문항
              ramInspectResultCd: 'RIR0000001',
              regUserId: this.$store.getters.user.userId,  // 작성자 ID
              targetEditFlag: (idx > -1 ? '' : 'C'),
              scenarioEditFlag: 'C',
              editFlag: 'C',
            })
          }
        })
      }
    },
    accepts() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let idx = this.$_.findIndex(this.process.targets, { ramCheckItemClassCd: this.target.ramCheckItemClassCd });
          this.process.targets.splice((idx > -1 ? idx : 0), 0, {
            ramChecklistAssessScenarioId: uid(),  // 시나리오 일련번호
            ramAssessChecklistTargetId: (idx > -1 ? this.process.targets[idx].ramAssessChecklistTargetId : uid()),  // 공종/유해인자/설비 일련번호
            ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
            processCd: this.process.processCd,  // 단위공정 코드
            processName: this.process.processName,  // 단위공정명
            ramCheckItemClassCd: this.target.ramCheckItemClassCd,  // 분류코드
            ramCheckItemClassName: this.$refs['ramCheckItemClassSelect'].getName(),  // 분류코드
            checkItemId: '',  // 분류id
            checkItemName: '',  // 분류명
            checklistQuestion: '',  // 체크리스트 문항
            ramInspectResultCd: 'RIR0000001',
            regUserId: this.$store.getters.user.userId,  // 작성자 ID
            targetEditFlag: (idx > -1 ? '' : 'C'),
            scenarioEditFlag: 'C',
            editFlag: 'C',
          })

          this.dialog = false;
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    innerBtnClicked(col, props, btn) {
      if (col.name === 'ramCheckItemClassName') {
        // 분류
        if (btn === '1') {
          // 직접추가
          let idx = this.$_.findIndex(this.process.targets, { ramCheckItemClassCd: props.row.ramCheckItemClassCd });
          this.process.targets.splice((idx > -1 ? idx : 0), 0, {
            ramChecklistAssessScenarioId: uid(),  // 시나리오 일련번호
            ramAssessChecklistTargetId: (idx > -1 ? this.process.targets[idx].ramAssessChecklistTargetId : uid()),  // 공종/유해인자/설비 일련번호
            ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
            processCd: this.process.processCd,  // 단위공정 코드
            processName: this.process.processName,  // 단위공정명
            ramCheckItemClassCd: props.row.ramCheckItemClassCd,  // 분류코드
            ramCheckItemClassName: props.row.ramCheckItemClassName,  // 분류코드
            checkItemId: '',  // 분류id
            checkItemName: '',  // 분류명
            checklistQuestion: '',  // 체크리스트 문항
            ramInspectResultCd: 'RIR0000001',
            regUserId: this.$store.getters.user.userId,  // 작성자 ID
            targetEditFlag: (idx > -1 ? '' : 'C'),
            scenarioEditFlag: 'C',
            editFlag: 'C',
          })
        } else {
          // 추가
          this.popupOptions.title = 'LBL0001095'; // 평가대상 검색
          this.popupOptions.param = {
            processCd: this.process.processCd,
            ramCheckItemClassCd: props.row.ramCheckItemClassCd,
          };
          this.popupOptions.target = () => import(`${'@/pages/ram/checklist/checklistTargetPop.vue'}`);
          this.popupOptions.width = '80%';
          this.popupOptions.visible = true;
          this.popupOptions.closeCallback = this.closeTargetPopup;
        }
      } else if (col.name === 'checkItemName') {
        // 평가대상
        let idx = this.$_.findIndex(this.process.targets, { 
          ramCheckItemClassCd: props.row.ramCheckItemClassCd,
          checkItemName: props.row.checkItemName 
        });
        this.process.targets.splice((idx > -1 ? idx : 0), 0, {
          ramChecklistAssessScenarioId: uid(),  // 시나리오 일련번호
          ramAssessChecklistTargetId: (idx > -1 ? this.process.targets[idx].ramAssessChecklistTargetId : uid()),  // 공종/유해인자/설비 일련번호
          ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
          processCd: this.process.processCd,  // 단위공정 코드
          processName: this.process.processName,  // 단위공정명
          ramCheckItemClassCd: props.row.ramCheckItemClassCd,  // 분류코드
          ramCheckItemClassName: props.row.ramCheckItemClassName,  // 분류코드
          checkItemId: props.row.checkItemId,  // 분류id
          checkItemName: props.row.checkItemName,  // 분류명
          checklistQuestion: '',  // 체크리스트 문항
          ramInspectResultCd: 'RIR0000001',
          regUserId: this.$store.getters.user.userId,  // 작성자 ID
          targetEditFlag: (idx > -1 ? '' : 'C'),
          scenarioEditFlag: 'C',
          editFlag: 'C',
        })
      }
    },
    saveTarget() {
      if (this.$comm.validTable(this.grid.columns, this.process.targets)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.targetScenarioSave.ramRiskAssessmentPlanId = this.process.ramRiskAssessmentPlanId
            this.targetScenarioSave.processCd = this.process.processCd
            this.targetScenarioSave.targets = []
            this.targetScenarioSave.scenarios = []
            let tempTargets = [];

            this.$_.forEach(this.process.targets, target => {
              if (this.$_.findIndex(tempTargets, { 
                ramCheckItemClassCd: target.ramCheckItemClassCd,
                checkItemName: target.checkItemName,
              }) === -1 && target.targetEditFlag) {
                tempTargets.push(target);
              }
            });
            this.targetScenarioSave.targets = this.$_.map(tempTargets, target => {
              return this.$_.extend(target, {
                editFlag: target.targetEditFlag
              })
            })
            this.targetScenarioSave.scenarios = this.$_.map(this.process.targets, target => {
              return this.$_.extend(target, {
                editFlag: target.scenarioEditFlag
              })
            })

            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveTargetCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveCallback', this.process.ramRiskAssessmentPlanId)
      this.getTargets();
      /**
       * 계획정보수정
       *  변경사항을 적용하기 위함
       */
      if (this.updateBtnData.flag) {
        this.updateBtnData.research = uid();
      }
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let message = 'MSGREMOVE'; // 삭제하시겠습니까?
        if (this.updateBtnData.flag) {
          // 현재 평가실행 중인 유해위험요인입니다.\n관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n진행하시겠습니까?
          message = 'MSG0000387'
        }
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: message,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
            this.$_.forEach(selectData, item => {
              this.process.targets = this.$_.reject(this.process.targets, item);
            })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              /**
               * 계획정보수정
               *  변경사항을 적용하기 위함
               */
              if (this.updateBtnData.flag) {
                this.updateBtnData.research = uid();
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
